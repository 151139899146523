import React, { useState } from 'react';

import Hero from './pages/hero/hero'
import Features from './pages/features/features'
import Packs from './pages/packs/packs'
import PartyPortada from './pages/party/partyPortada'
import Footer from './pages/footer/footer'

import { SvgContacto } from './assets/svg/svg'

function App() {

  const [selected, setSelected] = useState(
    {
      party: 'Portada',
      pack: 'Portada'
    }
  )

  const handleParty = party => {
    scrollToParty()
    setSelected({ ...selected, party: party, pack: 'Portada' })
  }

  // Changes Packs Status to render selected & scrolls to center view + changes Party section to "Main" 
  const handlePack = pack => {
    scrollToPack()
    setSelected({ ...selected, party: 'Portada', pack: pack })
  }

  // Scroll to center view on Party section
  const scrollToParty = () => {
    setSelected({ ...selected, party: 'Portada' })
    if (document.getElementById('party-portada')) {
      document.getElementById('party-portada').scrollIntoView({ block: "center", behavior: "smooth" })
    }
  }

  // Scroll to center view on Packs section
  const scrollToPack = () => {
    if (document.getElementById('packs')) {
      document.getElementById('packs').scrollIntoView({ block: "center", behavior: "smooth" })
    }
  }

  const openWhatsapp = () => {
    window.open('https://wa.me/34622173242?text=Hola,')
  }

  // Establish min and max date selectable at input date fields
  const minDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0] // Min date 7 days from today
  const maxDate = new Date(Date.now() + 180 * 24 * 60 * 60 * 1000).toISOString().split('T')[0] // Max date 6 months from today


  return (
    <div className="App">
      <Hero goToParty={scrollToParty} />
      <Features goToParty={scrollToParty} />
      <Packs
        currentPack={selected.pack}
        handleDia={() => handlePack('dia')}
        handleCumple={() => handlePack('cumple')}
        handleNoche={() => handlePack('noche')}
        handlePortada={() => handlePack('Portada')}
        today={minDate} sixMonths={maxDate}
      />
      <PartyPortada
        stateParty={selected.party}
        personalizada={() => handleParty('Personalizada')}
        presupuesto={() => handleParty('Presupuesto')}
        portada={() => handleParty('Portada')}
        today={minDate} sixMonths={maxDate}
      />
      <Footer />
      <button className='whatsApp-Icon' onClick={openWhatsapp}><SvgContacto/></button>
    </div>
  );
}

export default App;
