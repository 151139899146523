import React from 'react'
import './features.css'

import Feature from '../../components/feature/feature'
import Separator from '../../UI/separator/separator'
// import {ButtonSmall} from '../../components/button/button'

import { SvgGrupo, SvgLista, SvgPremium, SvgContacto } from '../../assets/svg/svg'




const features = (props) => {

    const sendWhatsApp = () => {
        window.open(`https://wa.me/34622173242?text=Hola,`)
    }

    return (
        <div className='features-page'>
            <div className='features'>
                <Feature
                    icon={<SvgGrupo />}
                    title={'¡Todas Juntas!'}
                    content={<p className='feature-text'>Forma un grupo con hasta 10 amigas</p>} />

                <Separator />

                <Feature
                    icon={<SvgLista />}
                    title={'Tú eliges'}
                    content={<p className='feature-text'>Personaliza los detalles de tu fiesta</p>} />

                <Separator />


                <Feature
                    icon={<SvgPremium />}
                    title={'Los mejores'}
                    content={<p className='feature-text'>Un equipo de estilistas experimentados a vuestra disposición</p>} />

                <Separator />

                <Feature
                    icon={<SvgContacto />}
                    title={'¿Alguna duda?'}
                    content={<p className='feature-text'><button onClick={sendWhatsApp}>Contáctanos</button> y te ayudaremos</p>} />

            </div>
        </div>
    )
}

export default features