import React from 'react'
import './emailPopup.css'


const EmailPopUp = (props) => {

    return (
        <div className='email-popup__backdrop'>
            <div className='email-popup'>
                <div className='email-popup__title' >
                    <h2>Suscríbete a nuestra Newsletter</h2>
                    <p>Te enviaremos todas las novedades y promociones para que no te pierdas nada</p>
                </div>
                <div className='email-popup__form'>
                    <label htmlFor='email' className='email-popup__form__label'>Email</label>
                    <input className='email-popup__form__input' type='email' id='email' placeholder='tuemail@ejemplo.com' onChange={props.handleUpdate} />
                    <div className='email-popup__form__button-container'>
                        <button className='email-popup__form__button-container__cancel' onClick={props.handleCancel}>No, gracias</button>
                        <button className='email-popup__form__button-container__submit' onClick={props.handleSubmit}>Suscribirme</button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default EmailPopUp